import { Link, useNavigate } from '@remix-run/react';
import { type FormEvent, useRef, useState } from 'react';
import { $path } from 'remix-routes';

import {
  type DtoProduct,
  type DtoProgram,
} from '@lp-lib/api-service-client/public';

import { EMAIL_PATTERN } from '../../../../src/components/Access/types';
import { EditIcon } from '../../../../src/components/icons/EditIcon';
import config from '../../../../src/config';
import { apiService } from '../../../../src/services/api-service';
import { hubspotTrackInstantQuoteFormSubmitted } from '../../../../src/tracking/hubspot';

const HEADCOUNT_OPTIONS = ['1-10', '11-25', '26+'];

export function AnonProgramDetailsSideAction(props: {
  program: DtoProgram;
  product: DtoProduct | null;
}) {
  const { program, product } = props;

  const [email, setEmail] = useState('');
  const emailRef = useRef<HTMLInputElement>(null);
  const [emailErr, setEmailErr] = useState<string | null>(null);
  const [headcount, setHeadcount] = useState('');
  const [headcountErr, setHeadcountErr] = useState<string | null>(null);

  const navigate = useNavigate();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!headcount) {
      setHeadcountErr('Please select your team size');
      return;
    }
    if (!email) {
      emailRef.current?.focus();
      setEmailErr('Please enter your email');
      return;
    }
    if (!EMAIL_PATTERN.test(email)) {
      emailRef.current?.focus();
      setEmailErr('Please enter a valid email');
      return;
    }

    try {
      await apiService.auth.checkEmailAvailability({
        email,
      });
    } catch (error) {
      const apiErr = apiService.utils.CastAPIError(error);
      if (apiErr?.msg === 'free_email_address') {
        emailRef.current?.focus();
        setEmailErr('Please enter your work email address');
        return;
      }
    }

    hubspotTrackInstantQuoteFormSubmitted({
      email,
      headcount: 10,
      kind: 'program',
      programName: program.name,
    });

    if (headcount === '1-10') {
      const params = new URLSearchParams(window.location.search);
      params.set('headcount', '10');
      params.set('register-for', 'subscription');
      params.set('method', 'slack');
      if (product) {
        params.set('product-id', product.id);
        params.set('trial', 'true');
      }
      navigate(`/register?${params.toString()}`);
    } else {
      window.location.href = config.hubspot.bookDemoUrl;
    }
  };

  return (
    <form
      className='w-full px-4 pt-6 pb-2 bg-main-layer rounded-xl'
      onSubmit={handleSubmit}
    >
      <div className='text-center text-xl font-bold text-tertiary'>
        Get Started for Free
      </div>

      {/* 
        This is a workaround to prevent the form from being collected by Hubspot.
        Refer to https://community.hubspot.com/t5/Lead-Capture-Tools/Ignore-a-specific-non-Hubspot-form-from-being-collected/m-p/246957
      */}
      <label htmlFor='cc-num' className='hidden'></label>
      <input
        name='cc-num'
        className='hidden'
        defaultValue='HubspotCollectedFormsWorkaround'
        id='cc-num'
      />

      <div className='mt-4 w-full'>
        <div className='text-sms text-center'>
          How many team members do you have?
        </div>

        <div className='mt-3 w-full flex items-center gap-2'>
          {HEADCOUNT_OPTIONS.map((option) => (
            <button
              type='button'
              key={option}
              onClick={() => {
                setHeadcount(option);
                setHeadcountErr(null);
              }}
              className={`
                btn w-full h-15 rounded-xl 
                bg-layer-002 hover:bg-lp-gray-003
                border ${
                  option === headcount ? 'border-white' : 'border-secondary'
                } 
                flex items-center justify-center
            `}
            >
              <div className='text-white font-bold'>{option}</div>
            </button>
          ))}
        </div>

        <div className='mt-1 px-2 text-red-006 text-3xs h-3 whitespace-nowrap'>
          {headcountErr}
        </div>
      </div>

      <div className='mt-1 w-full flex flex-col gap-1.5'>
        <div className='font-bold'>Email</div>

        <input
          ref={emailRef}
          name='email'
          type='email'
          value={email}
          onChange={(e) => {
            setEmail(e.currentTarget.value);
            setEmailErr(null);
          }}
          className={`mt-1 w-full h-12.5 m-0 transition-colors ${
            emailErr ? 'field-error' : 'field'
          }`}
          placeholder='Enter your work email address'
        />

        <div className='mt-1 px-2 text-red-006 text-3xs h-3 whitespace-nowrap'>
          {emailErr}
        </div>
      </div>

      <button
        type='submit'
        className='mt-2.5 btn-delete rounded w-full h-15 flex items-center justify-center font-bold'
      >
        {headcount === '1-10' ? 'Start Free Trial' : 'Book a Demo'}
      </button>
    </form>
  );
}

export function ProgramDetailsSideActionAdmin(props: { program: DtoProgram }) {
  const { program } = props;

  return (
    <div className='relative w-full bg-main-layer rounded-xl p-2'>
      <p className='my-5 text-center text-xl font-bold text-tertiary'>
        Admin Actions
      </p>

      <Link
        to={$path(
          '/admin/programs/v2/:id/edit',
          { id: program.id },
          {
            'back-to': `${window.location.pathname}${window.location.search}`,
          }
        )}
        className='btn-delete rounded w-full h-15 flex items-center justify-center gap-2'
      >
        <EditIcon className={'w-5 h-5 fill-current'} />
        Edit
      </Link>
    </div>
  );
}
